import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Divider,
  Grid,
  Loader,
  Feed,
  Dimmer,
  DropdownProps,
  MenuItemProps,
  BreadcrumbSectionProps,
  ButtonProps,
} from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';
import { apis } from '../../api';
import QuestionItem from './QuestionItem';
import ValidationRequestModal from '../ValidationRequestModal';
import BatchAnswerModal from './BatchAnswerModal';
import QuestionSideBar from './QuestionSideBar';
import AddTemplateModal from './AddTemplateModal';
import OrderTemplateModal from './OrderTemplateModal';
import EditTemplateModal from './EditTemplateModal';
import FeedControl from './FeedControl';
import {
  Question,
  QuestionCategory,
  QuestionChallenge,
  QuestionTemplate,
  QuestionChallengeOption,
  QuestionMainCategory,
  QuestionSubCategory,
} from '@types';

export const DIVIDE_SIZE = 3;

export enum QuestionSortType {
  LATEST = 'latest',
  OLDEST = 'oldest',
  END_DATE = 'endDate',
  RESULT_DATE = 'resultDate',
}

type QuestionContainerProps = {
  questionList: Question[];
  questionTagList: string[];
  questionTemplateList: QuestionTemplate[];
  filterStartDatetime: string;
  filterEndDatetime: string;
  setFilterStartDatetime: React.Dispatch<React.SetStateAction<string>>;
  setFilterEndDatetime: React.Dispatch<React.SetStateAction<string>>;
  onAddQuestionTemplate: (values: any) => Promise<void>;
  onEditQuestionTemplate: (values: any) => void;
  onUpdateQuestionTemplateOrder: (values: any) => void;
  onDeleteQuestionTemplate: (values: any) => void;
  onAnswerQuestionList: any;
  onRefreshQuestionPress: () => void;
  isLoading: boolean;
};

const QuestionContainer = ({
  questionList,
  questionTagList,
  questionTemplateList,
  filterStartDatetime,
  filterEndDatetime,
  setFilterStartDatetime,
  setFilterEndDatetime,
  onAddQuestionTemplate,
  onEditQuestionTemplate,
  onUpdateQuestionTemplateOrder,
  onDeleteQuestionTemplate,
  onAnswerQuestionList,
  onRefreshQuestionPress,
  isLoading,
}: QuestionContainerProps) => {
  const [batchAnswerModalOpen, setBatchAnswerModalOpen] =
    useState<boolean>(false);
  const [selectedChallengeId, setSelectedChallengeId] = useState<number | ''>(
    '',
  );

  const [selectedChallengeType, setSelectedChallengeType] =
    useState<string>('PURCHASE_CHALLENGE');
  const [questions, setQuestions] = useState<Question[]>([]);

  const [questionCount, setQuestionCount] = useState<{
    purchaseChallengeQuestion: number;
    collaboQuestion: number;
    raceQuestion: number;
  }>({
    purchaseChallengeQuestion: 0,
    collaboQuestion: 0,
    raceQuestion: 0,
  });

  const [questionAllTagOptions, setQuestionAllTagOptions] = useState<
    {
      key: string;
      text: string;
      value: string;
    }[]
  >([]);
  const [questionAllTagOptionsWithNumber, setQuestionAllTagOptionsWithNumber] =
    useState<
      {
        text: string;
        key: string;
        value: string;
      }[]
    >([]);

  const [mainCategories, setMainCategories] = useState<QuestionMainCategory[]>(
    [],
  );
  const [subCategories, setSubCategories] = useState<QuestionSubCategory[]>([]);
  const [challengeOptions, setChallengeOptions] = useState<
    QuestionChallengeOption[]
  >([]);
  const [questionCategories, setQuestionCategories] = useState<
    QuestionCategory[]
  >([]);

  const [activeMainCategory, setActiveMainCategory] = useState<string>('ALL');

  const [isAnswerFilter, setIsAnswerFilter] = useState<
    'notAnswered' | 'answered'
  >('notAnswered');
  const [filteredQuestions, setFilteredQuestions] = useState<Question[]>([]);
  const [divideGroup, setDivideGroup] = useState<number>(-1); // 전체
  const [addTemplateModalOpen, setAddTemplateModalOpen] =
    useState<boolean>(false);
  const [editTemplateModalOpen, setEditTemplateModalOpen] =
    useState<boolean>(false);
  const [orderTemplateModalOpen, setOrderTemplateModalOpen] =
    useState<boolean>(false);

  const [validationModalOpen, setValidationModalOpen] =
    useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);
  const [questionsForDownload, setQuestionsForDownload] = useState<any>([]);
  const [downloading, setDownloading] = useState<boolean>(false);

  const [sortType, setSortType] = useState<QuestionSortType>(
    QuestionSortType.LATEST,
  );

  const [selectedTagForFilter, setSelectedTagForFilter] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<{
    mainCategory: string;
    subCategory: string;
  }>({
    mainCategory: '',
    subCategory: '',
  });

  useEffect(() => {
    setFilter();
  }, [
    sortType,
    questions,
    isAnswerFilter,
    selectedCategory.mainCategory,
    selectedCategory.subCategory,
    divideGroup,
    questionAllTagOptions,
    selectedChallengeType,
    selectedChallengeId,
    selectedTagForFilter,
  ]);

  useEffect(() => {
    getQuestionCategories();
  }, []);

  useEffect(() => {
    setSelectedChallengeId('');
  }, [selectedChallengeType, isAnswerFilter]);

  useEffect(() => {
    if (!filteredQuestions) return;
    initializeCategories();
    classifyChallengeOption();
  }, [filteredQuestions]);

  useEffect(() => {
    classifyQuestionList();
  }, [questionList]);

  useEffect(() => {
    getQuestionTags();
  }, [questionTagList]);

  const getQuestionCategories = async () => {
    const { questionCategories: _questionCategories } =
      await apis.question.getQuestionCategories();
    setQuestionCategories(_questionCategories);
  };

  const classifyQuestionList = async () => {
    const _questions = [] as Question[];

    let _purchaseChallengeQuestionCount = 0;
    let _collaboQuestionCount = 0;
    let _raceQuestionCount = 0;
    questionList
      .filter(
        (_question: Question) => _question.title.indexOf('[챌린지 개설]') < 0,
      )
      .forEach((_question: Question) => {
        _questions.push(_question);
        if (_question.challenge) {
          if (_question.challenge.challengeType === 'collabo') {
            _collaboQuestionCount += 1;
          }
        }
        if (_question.serviceType === 'PURCHASE_CHALLENGE') {
          _purchaseChallengeQuestionCount += 1;
        }
        if (_question.serviceType === 'RACE') {
          _raceQuestionCount += 1;
        }
      });
    setQuestions(_questions);

    setQuestionCount({
      collaboQuestion: _collaboQuestionCount,
      purchaseChallengeQuestion: _purchaseChallengeQuestionCount,
      raceQuestion: _raceQuestionCount,
    });
  };

  const classifyChallengeOption = async () => {
    const removeDuplicates = (arr: QuestionChallengeOption[]) => {
      const map = new Map();

      return arr.filter((item) => {
        const key = `${item.key}-${item.challengeType}`;

        if (map.has(key)) {
          return false;
        }

        map.set(key, true);

        return true;
      });
    };

    const _challengeOptions = filteredQuestions
      .filter((_question: Question) => {
        return (
          _question.title.indexOf('[챌린지 개설]') < 0 &&
          ((_question.serviceType === 'CHALLENGE' && _question.challenge) ||
            (_question.serviceType === 'PURCHASE_CHALLENGE' &&
              _question.purchaseChallenge))
        );
      })
      .map((_question: Question) => {
        const challenge =
          _question.serviceType === 'CHALLENGE'
            ? _question.challenge
            : {
                ..._question.purchaseChallenge,
                challengeType: 'PURCHASE',
              };

        return {
          key: challenge!.id!,
          text: `[${challenge!.id}] ${challenge!.title}`,
          value: challenge!.id!,
          challengeType: String(challenge!.challengeType),
        };
      });

    setChallengeOptions(removeDuplicates(_challengeOptions));
  };

  const filterByselectedChallengeType = (question: Question) => {
    if (['RACE', 'PURCHASE_CHALLENGE'].includes(selectedChallengeType)) {
      return question.serviceType === selectedChallengeType;
    }
    if (selectedChallengeType === 'CHALLENGE') {
      return (
        question.serviceType === selectedChallengeType &&
        question.challenge?.challengeType !== 'collabo'
      );
    }
    return question.challenge?.challengeType === 'collabo';
  };

  const initializeCategories = () => {
    const _mainCategories: QuestionMainCategory[] = [];
    const _subCategories: QuestionSubCategory[] = [];
    const _mainCategoryKeywords: string[] = [];

    const _questions = questions.filter((question: Question) =>
      filterByselectedChallengeType(question),
    );
    const _questionCategories = [
      'CHALLENGE',
      'RACE',
      'PURCHASE_CHALLENGE',
    ].includes(selectedChallengeType)
      ? questionCategories.filter(
          (_category: QuestionCategory) =>
            _category.serviceType === selectedChallengeType,
        )
      : questionCategories.filter(
          (_category: QuestionCategory) =>
            _category.serviceType === 'CHALLENGE',
        );

    _mainCategories.push({
      key: 'ALL',
      value: 'ALL',
      text: `전체 (${_questions.length})`,
    });
    _questionCategories.forEach((category: QuestionCategory) => {
      if (!_mainCategoryKeywords.includes(category.mainCategory)) {
        _mainCategoryKeywords.push(category.mainCategory);
        const count = _questions.filter(
          (question: Question) =>
            question.category?.mainCategory === category.mainCategory,
        ).length;
        if (count > 0) {
          _mainCategories.push({
            key: category.mainCategory,
            value: category.mainCategory,
            text: category.mainCategory + ` (${count})`,
          });
        }
      }
      _subCategories.push({
        key: category.id,
        value: category.id,
        text: category.mainCategory + ' / ' + category.subCategory,
        mainCategory: category.mainCategory,
      });
    });
    setMainCategories(_mainCategories);
    setSubCategories(_subCategories);
  };

  const getFormatTagsObject = (_questions: Question[]) => {
    const numberOfTags = getNumberOfTags(getMergedTagsSingleArray(_questions));
    return Object.entries(numberOfTags).map(
      ([key, value]: [string, number]) => ({
        text: `${key} (${value})`,
        key,
        value: key,
      }),
    );
  };

  const filterByAnswer = (question: Question) => {
    return isAnswerFilter === 'notAnswered'
      ? question.answer === null
      : question.answer !== null;
  };

  const filterByDivideGroup = (question: Question) => {
    if (divideGroup < 0) {
      return true;
    }
    if (question.challenge) {
      return question.challenge.id % DIVIDE_SIZE === divideGroup;
    }
    return question.id % DIVIDE_SIZE === divideGroup;
  };

  const filterByDropdown = () => {
    const { mainCategory: _mainCategory, subCategory: _subCategory } =
      selectedCategory;
    let _filteredQuestions = [...questions];
    // 태그
    if (selectedTagForFilter) {
      _filteredQuestions = _filteredQuestions.filter((question) =>
        question.tags.some((tag: string) => tag === selectedTagForFilter),
      );
    }
    // 메인카테고리
    if (_mainCategory) {
      if (_mainCategory !== 'ALL') {
        _filteredQuestions = _filteredQuestions.filter(
          (q) => q.category?.mainCategory === _mainCategory,
        );
      }
    }

    // 서브카테고리
    if (_subCategory) {
      _filteredQuestions = _filteredQuestions.filter(
        (q) =>
          q.category?.mainCategory + ' / ' + q.category?.subCategory ===
          _subCategory,
      );
    }

    // 챌린지
    if (selectedChallengeId) {
      _filteredQuestions = _filteredQuestions.filter((q) => {
        const challenge =
          selectedChallengeType === 'CHALLENGE'
            ? q.challenge
            : q.purchaseChallenge;

        return challenge?.id === selectedChallengeId;
      });
    }

    return _filteredQuestions;
  };

  const sortQuestion = (_filteredQuestions: Question[]) => {
    switch (sortType) {
      case QuestionSortType.END_DATE:
        return _filteredQuestions.sort((a, b) => {
          if (selectedChallengeType === 'CHALLENGE') {
            if (!a.challenge && b.challenge) return 1;
            if (a.challenge && !b.challenge) return -1;

            if (a.challenge?.endDate && b.challenge?.endDate) {
              return (
                new Date(a.challenge?.endDate).getTime() -
                new Date(b.challenge?.endDate).getTime()
              );
            }

            return 0;
          } else if (selectedChallengeType === 'PURCHASE_CHALLENGE') {
            if (!a.purchaseChallenge && b.purchaseChallenge) return 1;
            if (a.purchaseChallenge && !b.purchaseChallenge) return -1;

            if (a.purchaseChallenge?.endDate && b.purchaseChallenge?.endDate) {
              return (
                new Date(a.purchaseChallenge?.endDate).getTime() -
                new Date(b.purchaseChallenge?.endDate).getTime()
              );
            }

            return 0;
          } else {
            return 0;
          }
        });
      case QuestionSortType.RESULT_DATE:
        return _filteredQuestions.sort((a, b) => {
          if (!a.challenge && b.challenge) return 1;
          if (a.challenge && !b.challenge) return -1;

          if (a.challenge?.resultDate && b.challenge?.resultDate) {
            return (
              new Date(a.challenge?.endDate).getTime() -
              new Date(b.challenge?.endDate).getTime()
            );
          }
          return 0;
        });
      case QuestionSortType.OLDEST:
        return _filteredQuestions.sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
        );
      case QuestionSortType.LATEST:
      default:
        return _filteredQuestions;
    }
  };

  const setFilter = () => {
    let _filteredQuestions = filterByDropdown();

    _filteredQuestions = _filteredQuestions.filter(
      (question) =>
        filterByselectedChallengeType(question) &&
        filterByAnswer(question) &&
        filterByDivideGroup(question),
    );

    _filteredQuestions = sortQuestion(_filteredQuestions);
    const countedTags = getFormatTagsObject(_filteredQuestions);

    setFilteredQuestions(_filteredQuestions);
    setQuestionAllTagOptionsWithNumber(countedTags);
  };

  const getQuestionTags = async () => {
    const _formattedQuestionTagOptions = questionTagList.map((tag: string) => {
      return {
        key: tag,
        text: tag,
        value: tag,
      };
    });
    setQuestionAllTagOptions(_formattedQuestionTagOptions);
  };

  const closeValidationModal = () => setValidationModalOpen(false);

  const handleValidate = async (_validated: boolean) => {
    closeValidationModal();
    await download();
    setValidated(_validated);
  };

  const download = async () => {
    if (downloading) return;
    setDownloading(true);
    const res = await apis.question.getQuestionsForDownload({
      gteCreatedAt: moment(filterStartDatetime)
        .subtract(9, 'h')
        .format('YYYY-MM-DD HH:mm:ss'),
      ltCreatedAt: moment(filterEndDatetime)
        .add(15, 'h')
        .format('YYYY-MM-DD HH:mm:ss'),
    });
    const { questions: _questionsForDownload } = res;
    setQuestionsForDownload(_questionsForDownload);
    setDownloading(false);
  };

  const answerQuestionList = async () => {
    setBatchAnswerModalOpen(true);
  };

  const getMergedTagsSingleArray = (_questions: Question[]) => {
    const mergedTags: string[] = [];
    for (let i = 0; i < _questions.length; ++i) {
      _questions[i].tags.forEach((el: string) => mergedTags.push(el));
    }
    return mergedTags;
  };

  const getNumberOfTags = (array: string[]) => {
    return array.reduce((acc: { [x: string]: number }, cur: string) => {
      if (acc[cur]) {
        acc[cur] += 1;
      } else {
        acc[cur] = 1;
      }
      return acc;
    }, {});
  };

  const handleItemClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    { name }: MenuItemProps,
  ) => {
    if (!name) return;
    setSelectedChallengeType(name);
    setSelectedChallengeId('');
    initializeCategories();
  };

  const handleFilter = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    { name }: BreadcrumbSectionProps,
  ) => {
    setIsAnswerFilter(name);
  };

  const handleQuestionTagAddition = (
    e: React.SyntheticEvent<HTMLElement, Event>,
    { value }: DropdownProps,
  ) => {
    const formattedQuestionTagOption = {
      key: String(value),
      text: String(value),
      value: String(value),
    };

    setQuestionAllTagOptions([
      ...questionAllTagOptions,
      formattedQuestionTagOption,
    ]);
  };

  const filterQuestion = (questionId: number) => {
    const newQuestions = _.filter(questions, (q) => q.id !== questionId);
    const newFilteredQuestions = _.filter(
      filteredQuestions,
      (q) => q.id !== questionId,
    );
    setQuestions(newQuestions);
    setFilteredQuestions(newFilteredQuestions);
  };

  const handleDivideGroup = (_divideGroup: number) => {
    setDivideGroup(_divideGroup);
  };

  const handleUpdate = async (updated: any) => {
    onUpdateQuestionTemplateOrder({
      values: updated,
    });
  };

  const deleteTemplate = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    { value }: ButtonProps,
  ) => {
    onDeleteQuestionTemplate({ id: value.id });
  };

  const handleCategoryRadioChange = (
    e: React.SyntheticEvent<HTMLElement, Event>,
    { name, value, ...props }: DropdownProps,
  ) => {
    if (name === 'mainCategory' && value) {
      const _subCategories: QuestionSubCategory[] = [];
      questionCategories.forEach((category: QuestionCategory) => {
        if (category.mainCategory === value) {
          _subCategories.push({
            key: category.id,
            value: category.id,
            text: category.mainCategory + ' / ' + category.subCategory,
            mainCategory: category.mainCategory,
          });
        }
      });
      setSubCategories(_subCategories);
      setActiveMainCategory(String(value));
      setSelectedCategory({
        ...selectedCategory,
        mainCategory: String(value),
        subCategory: '',
      });
    } else if (name === 'subCategory') {
      if (value) {
        const _selectedCategory = questionCategories.find(
          (category: QuestionCategory) => category.id === value,
        );
        setSelectedCategory({
          ...selectedCategory,
          subCategory:
            _selectedCategory?.mainCategory +
              ' / ' +
              _selectedCategory?.subCategory || '',
        });
      } else {
        initializeCategories();
        setSelectedCategory({
          ...selectedCategory,
          subCategory: '',
        });
      }
    }
  };

  return (
    <div style={{ margin: '40px 80px' }}>
      <Divider hidden />
      <Grid columns="equal">
        <QuestionSideBar
          selectedChallengeType={selectedChallengeType}
          handleItemClick={handleItemClick}
          filterStartDatetime={filterStartDatetime}
          filterEndDatetime={filterEndDatetime}
          setFilterStartDatetime={setFilterStartDatetime}
          setFilterEndDatetime={setFilterEndDatetime}
          questions={questions}
          questionCount={questionCount}
          questionsForDownload={questionsForDownload}
          showAddTemplateModal={() => setAddTemplateModalOpen(true)}
          showOrderTemplateModal={() => setOrderTemplateModalOpen(true)}
          showEditTemplateModal={() => setEditTemplateModalOpen(true)}
          showValidationModal={() => setValidationModalOpen(true)}
          validated={validated}
        />
        <Grid.Column width={1} />
        <Grid.Column>
          {isLoading && <Loader active inline="centered" />}
          {!isLoading && (
            <>
              <FeedControl
                selectedChallengeId={selectedChallengeId}
                isAnswerFilter={isAnswerFilter}
                handleFilter={handleFilter}
                filteredQuestionsLength={filteredQuestions.length}
                mainCategories={mainCategories}
                activeMainCategory={activeMainCategory}
                handleCategoryRadioChange={handleCategoryRadioChange}
                subCategories={subCategories}
                handleTagRadioChange={(
                  e: React.SyntheticEvent<HTMLElement, Event>,
                  { value }: DropdownProps,
                ) => {
                  if (typeof value === 'string') {
                    setSelectedTagForFilter(value);
                  }
                }}
                questionAllTagOptionsWithNumber={
                  questionAllTagOptionsWithNumber
                }
                challengeOptions={challengeOptions}
                setSelectedChallengeId={setSelectedChallengeId}
                handleDivideGroup={handleDivideGroup}
                divideGroup={divideGroup}
                answerQuestionList={answerQuestionList}
                onRefreshQuestionPress={onRefreshQuestionPress}
                selectedSubCategory={selectedCategory.subCategory}
                setSortType={setSortType}
                selectedChallengeType={selectedChallengeType}
              />
              {['CHALLENGE', 'PURCHASE_CHALLENGE', 'RACE', 'COLLABO'].includes(
                selectedChallengeType,
              ) && (
                <Feed>
                  {filteredQuestions.map((question: Question) => (
                    <QuestionItem
                      key={question.id}
                      question={question}
                      templates={questionTemplateList}
                      filterQuestion={filterQuestion}
                      questionAllTagOptions={questionAllTagOptions}
                      handleQuestionTagAddition={handleQuestionTagAddition}
                      questionCategories={questionCategories}
                    />
                  ))}
                </Feed>
              )}
            </>
          )}
        </Grid.Column>
      </Grid>

      <AddTemplateModal
        addTemplateModalOpen={addTemplateModalOpen}
        closeAddTemplateModal={() => setAddTemplateModalOpen(false)}
        onAddQuestionTemplate={onAddQuestionTemplate}
      />

      <OrderTemplateModal
        orderTemplateModalOpen={orderTemplateModalOpen}
        closeOrderTemplateModal={() => setOrderTemplateModalOpen(false)}
        questionTemplateList={questionTemplateList}
        handleUpdate={handleUpdate}
        deleteTemplate={deleteTemplate}
      />

      <EditTemplateModal
        editTemplateModalOpen={editTemplateModalOpen}
        closeEditTemplateModal={() => {
          setEditTemplateModalOpen(false);
        }}
        questionTemplateList={questionTemplateList}
        onEditQuestionTemplate={onEditQuestionTemplate}
      />

      <BatchAnswerModal
        batchAnswerModalOpen={batchAnswerModalOpen}
        onAnswerQuestionList={onAnswerQuestionList}
        closeBatchAnswerModal={() => setBatchAnswerModalOpen(false)}
      />

      <ValidationRequestModal
        // @ts-ignore
        validationModalOpen={validationModalOpen}
        closeValidationModal={closeValidationModal}
        setValidated={handleValidate}
        location={'문의 정보'}
        defaultText={'유저 문의내용 종류 분석'}
      />
      <Dimmer active={downloading}>
        <Loader active inline="centered" />
      </Dimmer>
    </div>
  );
};

export default QuestionContainer;
