import { Banner } from 'src/types/banner.types';
import { Icon } from 'semantic-ui-react';
import FlexBox from '@component/FlexBox/FlexBox';

type OnGoingProps = Pick<
  Banner,
  'landingType' | 'title' | 'color' | 'imageUrl' | 'info'
>;

const OnGoing = ({
  landingType,
  title,
  color,
  imageUrl,
  info,
}: OnGoingProps) => {
  if (imageUrl === '') {
    return <></>;
  }

  const { specialBadgeText, collaborationBadgeText } = info;

  return (
    <div
      style={{
        height: '100%',
        padding: '0 15px 20px 15px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'absolute',
        top: 0,
        left: 0,
      }}
    >
      <div>
        <div
          style={{
            marginBottom: (specialBadgeText || collaborationBadgeText).length
              ? 4
              : 0,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {collaborationBadgeText && (
            <div
              style={{
                inlineSize: 'max-content',
                color,
                fontSize: 14,
                marginTop: 5,
              }}
            >
              {collaborationBadgeText}
            </div>
          )}
        </div>
        {title && (
          <div
            style={{
              color,
              lineHeight: '20px',
              fontSize: 18,
              fontWeight: 700,
              whiteSpace: 'pre',
              marginTop: collaborationBadgeText ? 5 : 20,
            }}
          >
            {title}
          </div>
        )}
      </div>
      {!!(title || specialBadgeText || collaborationBadgeText).length && (
        <div style={{ color, marginTop: 5, fontSize: 14 }}>
          {landingType === 'CHALLENGE' ||
          landingType === 'PURCHASE_CHALLENGE' ? (
            <>
              <Icon name="user" size="small" style={{ color }} />
              n명/nnn명
            </>
          ) : (
            info.buttonText
          )}
          {'>'}
        </div>
      )}
    </div>
  );
};

type ClosedProps = Pick<Banner, 'title' | 'color' | 'info'>;

const Closed = ({ title, color, info }: ClosedProps) => {
  const {
    closedImageUrl,
    specialBadgeText,
    specialBadgeTextColor,
    specialBadgeBackgroundColor,
    collaborationBadgeText,
    collaborationBadgeTextColor,
  } = info;

  if (closedImageUrl === '') {
    return <></>;
  }

  return (
    <div
      style={{
        padding: '6px 15px',
        position: 'absolute',
        top: 0,
        left: 0,
      }}
    >
      <div
        style={{
          marginBottom: (specialBadgeText || collaborationBadgeText).length
            ? 4
            : 0,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {specialBadgeText && (
          <div
            style={{
              padding: 4,
              marginRight: 6,
              color: specialBadgeTextColor,
              fontSize: 10,
              borderRadius: 4,
              backgroundColor: specialBadgeBackgroundColor,
            }}
          >
            {specialBadgeText}
          </div>
        )}
        {collaborationBadgeText && (
          <div
            style={{
              inlineSize: 'max-content',
              color: collaborationBadgeTextColor,
              fontSize: 12,
            }}
          >
            {`${collaborationBadgeText} X 챌린저스`}
          </div>
        )}
      </div>
      {title && (
        <div
          style={{
            color,
            lineHeight: '20px',
            fontSize: 14,
            fontWeight: 500,
            whiteSpace: 'pre',
          }}
        >
          {title}
        </div>
      )}
    </div>
  );
};

type ExampleProps = Pick<Banner, 'landingType'>;

const Example = ({ landingType }: ExampleProps) => {
  return (
    <div
      style={{
        padding: '23px 165px 23px 20px',
        marginRight: 100,
        backgroundColor: '#EEE',
      }}
    >
      <div
        style={{
          lineHeight: '20px',
          fontSize: 18,
          fontWeight: 700,
          whiteSpace: 'pre',
        }}
      >
        {`이미지 가리지 않게 배치\n줄 바꿈 가능`}
      </div>

      <FlexBox.Row alignItems="center" style={{ marginTop: 6, fontSize: 14 }}>
        {landingType === 'CHALLENGE' || landingType === 'PURCHASE_CHALLENGE' ? (
          <>
            <Icon name="user" size="small" style={{ color: '#00000099' }} />
            n명/nnn명
          </>
        ) : (
          '자세히 보기'
        )}
        {'>'}
      </FlexBox.Row>
    </div>
  );
};

export default {
  OnGoing,
  Closed,
  Example,
};
