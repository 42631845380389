import { apis } from '@api/index';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import {
  ChallengeOption,
  EventOption,
  NoticeOption,
  RaceOption,
} from 'src/types/marketing.types';
import { newEventType } from '../../constant/eventType';
import {
  getChallengeOptions,
  getNoticeOptions,
  getRaceOptions,
} from '../../utils/dropdownOptions';
import BannerPage from './Banner';
import MarketingMenu from './MarketingMenu';

const Marketing = () => {
  const [noticeOptions, setNoticeOptions] = useState<NoticeOption[]>([]);
  const [challengeOptions, setChallengeOptions] = useState<ChallengeOption[]>(
    [],
  );
  const [eventOptions, setEventOptions] = useState<EventOption[]>([]);
  const [raceOptions, setRaceOptions] = useState<RaceOption[]>([]);

  useEffect(() => {
    Promise.all([
      _getNoticeOptions(),
      _getChallengeOptions(),
      _getEvents(),
      _getRaceOptions(),
    ]);
  }, []);

  const _getNoticeOptions = async () => {
    const _noticeOptions = await getNoticeOptions();
    setNoticeOptions(_noticeOptions);
  };

  const _getChallengeOptions = async () => {
    const _challengeOptions = await getChallengeOptions({
      gteRegisterEndDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      isOfficial: true,
      isDeleted: false,
    });
    setChallengeOptions(_challengeOptions);
  };

  const _getEvents = async () => {
    let { events: _events } = await apis.getEventDataList();
    _events = _events
      .filter((e: any) => Object.keys(newEventType).includes(e.data.type))
      .reverse();
    const _eventOptions = _events.map((item) => ({
      key: item.id,
      text: item.eventName,
      value: item.eventName,
    }));
    setEventOptions(_eventOptions);
  };

  const _getRaceOptions = async () => {
    const _raceOptions = await getRaceOptions();
    setRaceOptions(_raceOptions);
  };

  return (
    <div style={{ margin: 40 }}>
      <Grid columns="equal">
        <Grid.Column width={2}>
          <MarketingMenu />
        </Grid.Column>
        <Grid.Column>
          <BannerPage
            challengeOptions={challengeOptions}
            noticeOptions={noticeOptions}
            eventOptions={eventOptions}
            raceOptions={raceOptions}
          />
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default Marketing;
