import React from 'react';
import SideMenu from '../../../component/SideMenu/SideMenu';

const RaceMenu = () => {
  const menus = [{ text: '레이스 목록', value: '/race' }];

  return <SideMenu menus={menus} />;
};

export default RaceMenu;
