import _ from 'lodash';
import moment from 'moment';
import ReactDragList from 'react-drag-list';
import { Link } from 'react-router-dom';
import { Button, Form, Grid, Image, Radio } from 'semantic-ui-react';
import { apis, updateRacePriority } from '../../../../../api';
import { useState } from 'react';
import { RaceDisplayType, RaceForAdmin } from 'src/types/race.types';

type RaceTableProps = {
  raceList: RaceForAdmin[];
  copyRace: (race: RaceForAdmin) => void;
  removeRace: (race: RaceForAdmin) => void;
  setRaceList: React.Dispatch<React.SetStateAction<RaceForAdmin[]>>;
  orderBy: string;
};

const RaceTable = ({
  raceList,
  copyRace,
  removeRace,
  setRaceList,
  orderBy,
}: RaceTableProps) => {
  const changeDisplay = async (id: number, displayType: RaceDisplayType) => {
    await apis.updateRaceDisplay(id, { displayType });

    alert('변경 완료!');

    setRaceList((prev) => {
      const updatedList = [...prev];
      const targetIndex = updatedList.findIndex((race) => race.id === id);

      if (targetIndex === -1) return [];
      const _updatedList = _.cloneDeep(updatedList);
      _updatedList[targetIndex].displayType = displayType;

      return _updatedList;
    });
  };

  const handleUpdate = async (e: unknown, updated: unknown[]) => {
    // 바꿀 대상의
    const { oldIndex, newIndex } = e as {
      oldIndex: number;
      newIndex: number;
    };
    const raceId = raceList[oldIndex].id;

    // newPriorityIndex 바꾸고자 하는 위치 (priority는 1-base)
    const { success } = await updateRacePriority({
      raceId,
      newPriorityIndex: newIndex + 1,
    });

    if (success) {
      setRaceList(updated as React.SetStateAction<RaceForAdmin[]>);
    }
  };

  const handleClick = () => {
    sessionStorage.setItem('scroll-y', window.scrollY.toString());
  };

  return (
    <>
      <Grid basic="very" size="small" style={{ fontWeight: 700 }} stretched>
        <Grid.Row style={{ flexWrap: 'nowrap' }}>
          <Grid.Column width={1}>ID</Grid.Column>
          <Grid.Column width={2}>노출 타입</Grid.Column>
          <Grid.Column width={2}>액션버튼</Grid.Column>
          <Grid.Column width={1}>월</Grid.Column>
          <Grid.Column width={2}>썸네일</Grid.Column>
          <Grid.Column width={2}>대회명</Grid.Column>
          <Grid.Column width={2}>가격</Grid.Column>
          <Grid.Column width={2}>모집 기간</Grid.Column>
          <Grid.Column width={2}>대회 진행기간</Grid.Column>
          <Grid.Column width={1}>목표인원</Grid.Column>
          <Grid.Column width={1}>총 참가인원</Grid.Column>
          <Grid.Column width={1}>기념팩 구매자</Grid.Column>
          <Grid.Column width={1}>참가권 참가자</Grid.Column>
          <Grid.Column width={1}>남은인원</Grid.Column>
          <Grid.Column width={1}>기타</Grid.Column>
        </Grid.Row>
      </Grid>
      <ReactDragList
        rowKey="id"
        key={raceList.map((o) => o.id).join('-') + orderBy}
        dataSource={[...raceList]}
        handles={false}
        onUpdate={handleUpdate}
        disabled={orderBy === 'setting'}
        row={(_race) => {
          const race = _race as RaceForAdmin;
          return (
            <Grid key={race.id} style={{ flexWrap: 'nowrap' }}>
              <Grid.Column width={1}>{race.id}</Grid.Column>
              <Grid.Column width={2}>
                <Form>
                  <Form.Group grouped>
                    <Form.Field>
                      <Radio
                        label="어드민"
                        value="ADMIN"
                        name="isDisplayed"
                        checked={race.displayType === 'ADMIN'}
                        onChange={() => changeDisplay(race.id, 'ADMIN')}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Radio
                        label="전체"
                        value="ALL"
                        name="isDisplayed"
                        checked={race.displayType === 'ALL'}
                        onChange={() => changeDisplay(race.id, 'ALL')}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Radio
                        label="노출 중지"
                        value="ADMIN"
                        name="isDisplayed"
                        checked={race.displayType === 'NONE'}
                        onChange={() => changeDisplay(race.id, 'NONE')}
                      />
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Grid.Column>

              <Grid.Column width={2}>
                <Button
                  size={'mini'}
                  content={'상세'}
                  as={Link}
                  to={{ pathname: `/race/${race.id}` }}
                />
                <Button
                  size={'mini'}
                  content={'수정'}
                  as={Link}
                  onClick={handleClick}
                  to={{ pathname: `/race/${race.id}/edit` }}
                />
                <br />
                <Button
                  size={'mini'}
                  content={'복사'}
                  onClick={() => copyRace(race)}
                />
                <Button
                  size={'mini'}
                  content={'삭제'}
                  onClick={() => removeRace(race)}
                />
              </Grid.Column>

              <Grid.Column width={1}>{race.seasonTerm}</Grid.Column>
              <Grid.Column width={2}>
                <Image size={'mini'} src={race.thumbnailImageUrl} />
              </Grid.Column>
              <Grid.Column width={2}>{race.title}</Grid.Column>

              <Grid.Column width={2}>
                {race.packages.map((o) => (
                  <div key={o.name}>
                    {o.name} {o.price.toLocaleString()}
                  </div>
                ))}
              </Grid.Column>

              <Grid.Column width={2}>
                {moment(race.registerStartDate).format('YYYY-MM-DD')}~
                <br />
                {moment(race.registerEndDate).format('YYYY-MM-DD')}
              </Grid.Column>
              <Grid.Column width={2}>
                {moment(race.startDate).format('YYYY-MM-DD')}~
                <br />
                {moment(race.endDate).format('YYYY-MM-DD')}
              </Grid.Column>

              <Grid.Column width={1}>
                {race.maxRegisterCount.toLocaleString()}명
              </Grid.Column>
              <Grid.Column width={1}>
                {race.registerCount.toLocaleString()}명
              </Grid.Column>
              <Grid.Column width={1}>
                {race.packagePurchasedCount.toLocaleString()}명
              </Grid.Column>
              <Grid.Column width={1}>
                {(
                  race.registerCount - race.packagePurchasedCount
                ).toLocaleString()}
                명
              </Grid.Column>
              <Grid.Column width={1}>
                {Math.max(
                  race.maxRegisterCount - race.packagePurchasedCount,
                  0,
                ).toLocaleString()}
                명
              </Grid.Column>
              <Grid.Column width={1}>
                <Button
                  size={'mini'}
                  content={'바로가기'}
                  onClick={() =>
                    window.open(
                      `https://web${
                        process.env.REACT_APP_ENV_PHASE === 'STAGE'
                          ? '-stage'
                          : ''
                      }.chlngers.com/race/${race.id}`,
                    )
                  }
                />
              </Grid.Column>
            </Grid>
          );
        }}
      />
    </>
  );
};

export default RaceTable;
