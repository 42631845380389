import React, { useCallback, useEffect, useState } from 'react';
import {
  Accordion,
  Button,
  Form,
  Header,
  Icon,
  Image,
  Table,
  Input,
  TextArea,
  Grid,
  Modal,
  Radio,
  Loader,
  Dimmer,
  Label,
} from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import setCurrencyFormat from '../../../../global/setCurrencyFormat';
import { apis } from '../../../../api';
import {
  SKIN_CONCERNS,
  SKIN_TONES,
  SKIN_CATEGORIES,
  OLIVEYOUNG_MEMBERSHIP,
} from './constants';

export const UserBasicInfo = ({
  user,
  userSocials,
  badges,
  exp,
  recommenders,
  recommendee,
  experiments,
  userMarketingInfo,
  handleChangeInput,
  sendSms,
  sendPush,
  clearUserExp,
  activeIndex,
  handleAccordionClick,
  showVerifyModal,
  cancelVerify,
  editUserModalOpen,
  showEditUserModal,
  closeEditUserModal,
  handleChangeUserInfo,
  handleChangeUserInfoRadio,
  updateUserInfo,
  updateUserBadge,
  syncUserExp,
  registeredChallenges,
  registerCountModalOpen,
  badge,
  networking,
  showRegisterCountModal,
  closeRegisterCountModal,
  showExperimentGroupChangeModal,
  userAddressList,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [addressModalOpen, setAddressModalOpen] = useState(false);
  const [sns, setSns] = useState('instagram');
  const [naverId, setNaverId] = useState('');
  const [username, setUsername] = useState('');
  const [followerCount, setFollowerCount] = useState(0);
  const [postCount, setPostCount] = useState(0);
  const [buddyCount, setBuddyCount] = useState(0);
  const [visitorCount, setVisitorCount] = useState(0);
  const [oliveYoungInfo, setOliveYoungInfo] = useState({});
  const [socialModalOpen, setSocialModalOpen] = useState(false);
  const [experimentToggleOpen, setExperimentToggleOpen] = useState(false);

  useEffect(() => {
    _initOliveYoungInfo();
  }, []);

  const showModal = useCallback(() => {
    setModalOpen(true);
  });

  const closeModal = useCallback(() => {
    setModalOpen(false);
    setNaverId('');
    setUsername('');
    setFollowerCount(0);
    setPostCount(0);
    setBuddyCount(0);
    setVisitorCount(0);
    _initOliveYoungInfo();
  });

  const _initOliveYoungInfo = () => {
    const _oliveYoungInfo = _.cloneDeep(oliveYoungInfo);
    _oliveYoungInfo.username = '';
    _oliveYoungInfo.userKey = '';
    _oliveYoungInfo.membership = '';
    _oliveYoungInfo.reviewerRanking = '';
    _oliveYoungInfo.skinCategory = '';
    _oliveYoungInfo.skinTone = '';
    _oliveYoungInfo.skinConcerns = [];
    setOliveYoungInfo(_oliveYoungInfo);
  };

  const _updateUserInfoSocials = async () => {
    let body;
    switch (sns) {
      case 'instagram':
        body = { username, followerCount, postCount };
        break;
      case 'naver':
        body = { id: naverId };
        break;
      case 'naverBlog':
        body = { username, buddyCount, visitorCount };
        break;
      case 'oliveYoung':
        body = _.cloneDeep(oliveYoungInfo);

        // validation
        if (!body.username.trim()) {
          alert('올리브영 닉네임을 입력해주세요.');
          return;
        }
        if (body.userKey.trim().includes('http')) {
          alert(
            'user key 정보를 확인해주세요. url이 아닌 url 내 key=[값] 의 값을 입력해주셔야 합니다.',
          );
          return;
        }
        if (!body.membership.trim()) {
          alert('올리브영 등급을 입력해주세요.');
          return;
        }
        if (body.reviewerRanking.trim().length === 0) {
          // reviewerRanking null 처리
          body.reviewerRanking = null;
        } else {
          body.reviewerRanking = Number(body.reviewerRanking);
        }

        // skin 관련 정보 하나로 합치기
        body.skinTypes = [
          body.skinCategory,
          body.skinTone,
          ...body.skinConcerns,
        ];
        delete body.skinCategory;
        delete body.skinTone;
        delete body.skinConcerns;
    }

    const res = await apis.user.updateUserInfoSocials(user.id, {
      ...body,
      sns,
    });
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }
    alert('SNS 정보 업데이트가 완료되었어요.');
    closeModal();
  };

  const showAddressModal = useCallback(() => {
    setAddressModalOpen(true);
  });

  const closeAddressModal = useCallback(() => {
    setAddressModalOpen(false);
  });

  const showSocialModal = useCallback(() => {
    setSocialModalOpen(true);
  });

  const closeSocialModal = useCallback(() => {
    setSocialModalOpen(false);
  });

  const _changeSnsType = (e) => {
    const { value } = e.target;
    setSns(value);
  };

  const _changeOliveYoungValue = (k, v) => {
    const _oliveYoungInfo = _.cloneDeep(oliveYoungInfo);
    _oliveYoungInfo[k] = v.trim();
    setOliveYoungInfo(_oliveYoungInfo);
  };

  const _changeOliveYoungSkinTypes = (k, v) => {
    const _oliveYoungInfo = _.cloneDeep(oliveYoungInfo);
    switch (k) {
      // SKIN_TYPE, SKIN_TONE은 한 개만 선택되어야 함
      case 'skinCategory':
      case 'skinTone':
        _oliveYoungInfo[k] = v;
        break;
      // SKIN CONCERN의 경우 복수선택 가능함
      case 'skinConcerns':
        if (_oliveYoungInfo.skinConcerns.includes(v)) {
          _oliveYoungInfo.skinConcerns = _oliveYoungInfo.skinConcerns.filter(
            (o) => o !== v,
          );
        } else {
          _oliveYoungInfo.skinConcerns.push(v);
        }
        break;
      default:
        break;
    }
    setOliveYoungInfo(_oliveYoungInfo);
  };

  const isNaver = sns === 'naver';
  const isNaverBlog = sns === 'naverBlog';
  const isInstagram = sns === 'instagram';
  const isOliveYoung = sns === 'oliveYoung';

  const defualtAddress = userAddressList.find((o) => o.isDefault);

  return (
    <React.Fragment>
      <Header as="h5">유저 기본정보</Header>
      <Table basic="very" textAlign="center">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>id</Table.HeaderCell>
            <Table.HeaderCell>가입일</Table.HeaderCell>
            <Table.HeaderCell>사진</Table.HeaderCell>
            <Table.HeaderCell>이름</Table.HeaderCell>
            <Table.HeaderCell>닉네임</Table.HeaderCell>
            <Table.HeaderCell>이메일</Table.HeaderCell>
            <Table.HeaderCell>핸드폰</Table.HeaderCell>
            <Table.HeaderCell>생년월일</Table.HeaderCell>
            <Table.HeaderCell>성별</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell verticalAlign="top">{user.id}</Table.Cell>
            <Table.Cell verticalAlign="top">
              {moment(user.createdAt).format('YYYY-MM-DD HH:mm:ss')}
            </Table.Cell>
            <Table.Cell verticalAlign="top">
              <Image size="tiny" src={user.pictureUrl} />
            </Table.Cell>
            <Table.Cell verticalAlign="top">{user.name}</Table.Cell>
            <Table.Cell verticalAlign="top">{user.nickname}</Table.Cell>
            <Table.Cell verticalAlign="top">{user.email}</Table.Cell>
            <Table.Cell verticalAlign="top">{user.phone}</Table.Cell>
            <Table.Cell verticalAlign="top">
              {moment(user.birthday).format('YYYY-MM-DD')}
            </Table.Cell>
            <Table.Cell verticalAlign="top">{user.sex}</Table.Cell>
          </Table.Row>
        </Table.Body>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>은행</Table.HeaderCell>
            <Table.HeaderCell>은행계좌</Table.HeaderCell>
            <Table.HeaderCell>타임존</Table.HeaderCell>
            <Table.HeaderCell colSpan={6}>주소</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{user.account.bankName}</Table.Cell>
            <Table.Cell>{user.account.bankAccount}</Table.Cell>
            <Table.Cell>{user.timezone}</Table.Cell>
            <Table.Cell colSpan={6}>
              ({defualtAddress?.zipCode}) {defualtAddress?.address1}{' '}
              {defualtAddress?.address2}
              <Button
                size="mini"
                onClick={showAddressModal}
                content={'전체보기'}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>인증샷푸시허용여부</Table.HeaderCell>
            <Table.HeaderCell>챌린지푸시허용여부</Table.HeaderCell>
            <Table.HeaderCell>관계푸시허용여부</Table.HeaderCell>
            <Table.HeaderCell>광고푸시허용여부</Table.HeaderCell>
            <Table.HeaderCell>최근접속 기기 OS</Table.HeaderCell>
            <Table.HeaderCell>최근접속 버전정보</Table.HeaderCell>
            <Table.HeaderCell>탈퇴여부</Table.HeaderCell>
            <Table.HeaderCell>이용정지기한</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              {user.pushAchievementPossible ? '허용' : '미허용'}
            </Table.Cell>
            <Table.Cell>
              {user.pushChallengePossible ? '허용' : '미허용'}
            </Table.Cell>
            <Table.Cell>
              {user.pushRelationPossible ? '허용' : '미허용'}
            </Table.Cell>
            <Table.Cell>{user.pushAdPossible ? '허용' : '미허용'}</Table.Cell>
            <Table.Cell>{userMarketingInfo.deviceOs}</Table.Cell>
            <Table.Cell>{userMarketingInfo.versionNumber}</Table.Cell>
            <Table.Cell>
              {user.deletedDatetime
                ? moment(user.deletedDatetime).format('YYYY-MM-DD HH:mm:ss')
                : '-'}
            </Table.Cell>
            <Table.Cell>
              {user.banEndDatetime
                ? moment(user.banEndDatetime).format('YYYY-MM-DD HH:mm:ss')
                : '-'}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>SNS 업데이트</Table.HeaderCell>
            <Table.HeaderCell>편집</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Button onClick={showSocialModal} content={'전체보기'} />
              <Button onClick={showModal} content={'업데이트'} />
            </Table.Cell>
            <Table.Cell>
              <Button onClick={showEditUserModal}>편집</Button>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          {recommenders.length > 0 && (
            <>
              <Header as="h5">추천인으로 입력한 유저들</Header>
              <Table basic="very">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>id</Table.HeaderCell>
                    <Table.HeaderCell>추천일</Table.HeaderCell>
                    <Table.HeaderCell>user_id</Table.HeaderCell>
                    <Table.HeaderCell>user_nickname</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {recommenders.map((r) => (
                    <Table.Row key={r.id}>
                      <Table.Cell>{r.id}</Table.Cell>
                      <Table.Cell>
                        {moment(r.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                      </Table.Cell>
                      <Table.Cell>{r.userId}</Table.Cell>
                      <Table.Cell>{r.nickname}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </>
          )}
        </div>

        <div style={{ flex: 1 }}>
          {recommendee.id && (
            <>
              <Header as="h5">가입시 추천인 입력 정보</Header>[
              {recommendee.userId}] {recommendee.nickname} (
              {moment(recommendee.createdAt).format('YYYY-MM-DD HH:mm:ss')})
            </>
          )}
        </div>
      </div>

      <Grid.Column>
        <Accordion>
          <Accordion.Title
            active={activeIndex === 1}
            index={1}
            onClick={handleAccordionClick}
          >
            <Icon name="dropdown" />
            문자발송
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 1}>
            <Form>
              <Form.Field>
                <TextArea
                  rows={5}
                  size="tiny"
                  name="smsText"
                  placeholder="문자 내용"
                  onChange={handleChangeInput}
                />
              </Form.Field>
              <Button onClick={sendSms}>발송</Button>
            </Form>
          </Accordion.Content>
        </Accordion>
      </Grid.Column>

      <Grid.Column>
        <Accordion>
          <Accordion.Title
            active={activeIndex === 2}
            index={2}
            onClick={handleAccordionClick}
          >
            <Icon name="dropdown" />
            푸시
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 2}>
            <Form>
              <Form.Field>
                <Input
                  name="pushTitle"
                  placeholder="푸시 제목 (30자)"
                  onChange={handleChangeInput}
                />
              </Form.Field>
              <Form.Field>
                <TextArea
                  rows={2}
                  name="pushBody"
                  placeholder="푸시 내용 (100자)"
                  onChange={handleChangeInput}
                />
              </Form.Field>
              <Button onClick={sendPush}>발송</Button>
            </Form>
          </Accordion.Content>
        </Accordion>
      </Grid.Column>

      <Accordion>
        <Accordion.Title
          active={experimentToggleOpen}
          index={3}
          onClick={() => setExperimentToggleOpen(!experimentToggleOpen)}
        >
          <Header as="h5">
            <Icon name="dropdown" />
            실험 정보
          </Header>
        </Accordion.Title>
        <Accordion.Content active={experimentToggleOpen}>
          <Table basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>id</Table.HeaderCell>
                <Table.HeaderCell>실험명</Table.HeaderCell>
                <Table.HeaderCell>실험설명</Table.HeaderCell>
                <Table.HeaderCell>타겟버전</Table.HeaderCell>
                <Table.HeaderCell>전체실험군</Table.HeaderCell>
                <Table.HeaderCell>유저실험군</Table.HeaderCell>
                <Table.HeaderCell>진행여부</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {experiments.map((experiment, index) => (
                <Table.Row key={`${experiment.id}_${index}`}>
                  <Table.Cell>{experiment.id}</Table.Cell>
                  <Table.Cell>{experiment.name}</Table.Cell>
                  <Table.Cell>{experiment.description}</Table.Cell>
                  <Table.Cell>
                    시작 버전: {experiment.targetMin}
                    <br />
                    종료 버전: {experiment.targetMax}
                  </Table.Cell>
                  <Table.Cell>
                    {experiment.variants.map((item, idx) => (
                      <p key={idx}>
                        {idx}. {item.name} ({item.weight}%)
                      </p>
                    ))}
                  </Table.Cell>
                  <Table.Cell>
                    {experiment.groupId}.{' '}
                    {experiment.variants[experiment.groupId].name}{' '}
                    {experiment.variants[experiment.groupId].weight}% <br />
                    <Button
                      size="tiny"
                      content="변경"
                      onClick={() => showExperimentGroupChangeModal(experiment)}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    {experiment.ended ? '종료' : '진행중'}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Accordion.Content>
      </Accordion>

      <Header as="h5">
        경험치 정보
        <Button
          size="tiny"
          style={{ marginLeft: 10 }}
          content="경험치 동기화"
          onClick={syncUserExp}
        />
      </Header>
      <Table basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>등급</Table.HeaderCell>
            <Table.HeaderCell>신규등급</Table.HeaderCell>
            <Table.HeaderCell>총경험치</Table.HeaderCell>
            <Table.HeaderCell>역량</Table.HeaderCell>
            <Table.HeaderCell>자산</Table.HeaderCell>
            <Table.HeaderCell>정서</Table.HeaderCell>
            <Table.HeaderCell>건강</Table.HeaderCell>
            <Table.HeaderCell>생활</Table.HeaderCell>
            <Table.HeaderCell>습관</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{exp.grade}</Table.Cell>
            <Table.Cell>충전도 {exp.newGrade}</Table.Cell>
            <Table.Cell>
              {setCurrencyFormat(
                exp.ability +
                  exp.asset +
                  exp.relation +
                  exp.health +
                  exp.life +
                  exp.hobby,
              )}
            </Table.Cell>
            <Table.Cell>{setCurrencyFormat(exp.ability)}</Table.Cell>
            <Table.Cell>{setCurrencyFormat(exp.asset)}</Table.Cell>
            <Table.Cell>{setCurrencyFormat(exp.relation)}</Table.Cell>
            <Table.Cell>{setCurrencyFormat(exp.health)}</Table.Cell>
            <Table.Cell>{setCurrencyFormat(exp.life)}</Table.Cell>
            <Table.Cell>{setCurrencyFormat(exp.hobby)}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Header as="h5">
        배지 정보
        {/*<Button size='tiny' style={{marginLeft: 10}} content='배지 업데이트' onClick={updateUserBadge}/>*/}
      </Header>
      <Table basic="very">
        <Table.Header>
          <Table.Row>
            {_.range(0, 9).map((item) => {
              const badge = badges[item];
              if (badge) {
                return (
                  <Table.HeaderCell key={'h' + item}>
                    {badge.name}
                  </Table.HeaderCell>
                );
              } else {
                return null;
              }
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            {_.range(0, 9).map((item) => {
              const badge = badges[item];
              if (badge) {
                return (
                  <Table.Cell key={'b' + item}>
                    {badges[item].badges.map((b) => b.criteria).join(', ')}
                  </Table.Cell>
                );
              } else {
                return null;
              }
            })}
          </Table.Row>
        </Table.Body>
        <Table.Header>
          <Table.Row>
            {_.range(9, 21).map((item) => {
              const badge = badges[item];
              if (badge && badge.goalIds && badge.goalIds.length > 0) {
                return (
                  <Table.HeaderCell key={'h' + item}>
                    {badge.name}
                    <Label
                      size={'mini'}
                      style={{ cursor: 'pointer' }}
                      onClick={() => showRegisterCountModal(badge)}
                    >
                      +
                    </Label>
                  </Table.HeaderCell>
                );
              } else {
                return null;
              }
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            {_.range(9, 21).map((item) => {
              const badge = badges[item];
              if (badge && badge.goalIds && badge.goalIds.length > 0) {
                return (
                  <Table.Cell key={'b' + item}>
                    {badges[item].badges
                      .map((b) => b.criteria + badge.unit)
                      .join(', ')}
                  </Table.Cell>
                );
              } else {
                return null;
              }
            })}
          </Table.Row>
        </Table.Body>
        <Table.Header>
          <Table.Row>
            {_.range(21, 32).map((item) => {
              const badge = badges[item];
              if (badge && badge.goalIds && badge.goalIds.length > 0) {
                return (
                  <Table.HeaderCell key={'h' + item}>
                    {badge.name}
                    <Label
                      size={'mini'}
                      style={{ cursor: 'pointer' }}
                      onClick={() => showRegisterCountModal(badge)}
                    >
                      +
                    </Label>
                  </Table.HeaderCell>
                );
              } else {
                return null;
              }
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            {_.range(21, 32).map((item) => {
              const badge = badges[item];
              if (badge && badge.goalIds && badge.goalIds.length > 0) {
                return (
                  <Table.Cell key={'b' + item}>
                    {badges[item].badges
                      .map((b) => b.criteria + badge.unit)
                      .join(', ')}
                  </Table.Cell>
                );
              } else {
                return null;
              }
            })}
          </Table.Row>
        </Table.Body>
      </Table>

      <Modal
        size="tiny"
        open={registerCountModalOpen}
        onClose={closeRegisterCountModal}
      >
        <Dimmer active={networking}>
          <Loader active inline="centered" />
        </Dimmer>
        <Modal.Header>배지 관련 챌린지 참가 횟수</Modal.Header>
        <Modal.Content>
          {badge.category === 'ROUTINE' ? (
            badge.goalIds.map((goal) => {
              const challenges = registeredChallenges.filter((c) =>
                goal.goalIds.includes(c.goalId),
              );
              return (
                <div key={goal.title}>
                  <Image size={'tiny'} src={goal.imageUrl} />
                  <h5 style={{ margin: 0 }}>{goal.title}</h5>
                  <div>
                    목표목록 [{goal.goalIds.join(', ')}]<br />
                    {challenges.length}
                    {badge.unit}
                    <br />
                  </div>
                </div>
              );
            })
          ) : badge.category === 'CHALLENGE' ? (
            <div>
              <p>
                {registeredChallenges.length}
                {badge.unit}
              </p>
              <p>{badge.goalIds.join(', ')}</p>
            </div>
          ) : null}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="black"
            content="닫기"
            onClick={closeRegisterCountModal}
          />
        </Modal.Actions>
      </Modal>

      <Grid columns="equal">
        <Grid.Row>
          <Grid.Column>
            <Accordion>
              <Accordion.Title
                active={activeIndex === 4}
                index={4}
                onClick={handleAccordionClick}
              >
                <Icon name="dropdown" />
                경험치 초기화
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 3}>
                <p>
                  경험치 초기화 진행시 유저의 모든 경험치가 0으로 초기화 됩니다.
                  그래도 진행하시겠습니까?
                </p>
                <Button
                  size="mini"
                  onClick={clearUserExp}
                  content="경험치 초기화"
                />
              </Accordion.Content>
            </Accordion>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Modal size="tiny" open={editUserModalOpen} onClose={closeEditUserModal}>
        <Modal.Header>유저 정보 변경</Modal.Header>
        <Modal.Content>
          <div style={{ marginBottom: 10 }}>
            {user.id} / {user.name} / {user.nickname}
          </div>
          <Form>
            <Form.Field>
              <label>이메일</label>
              <input
                name="email"
                defaultValue={user.email}
                onChange={handleChangeUserInfo}
              />
            </Form.Field>
            <Form.Field>
              <label>이름</label>
              <input
                name="name"
                defaultValue={user.name}
                onChange={handleChangeUserInfo}
              />
            </Form.Field>
            <Form.Field>
              <label>핸드폰번호</label>
              <input
                name="phone"
                defaultValue={user.phone}
                onChange={handleChangeUserInfo}
              />
            </Form.Field>
            <Form.Group grouped>
              <label>성별</label>
              <Form.Field
                control={Radio}
                label="M"
                name="sex"
                value="M"
                defaultChecked={user.sex === 'M'}
                onChange={handleChangeUserInfoRadio}
              />
              <Form.Field
                control={Radio}
                label="F"
                name="sex"
                value="F"
                defaultChecked={user.sex === 'F'}
                onChange={handleChangeUserInfoRadio}
              />
            </Form.Group>
            <Form.Field>
              <label>생년월일</label>
              <input
                name="birthday"
                defaultValue={user.birthday}
                onChange={handleChangeUserInfo}
              />
            </Form.Field>
            <Form.Group grouped>
              <label>가입경로</label>
              <Form.Field
                control={Radio}
                label="ADMIN"
                name="source"
                value="ADMIN"
                defaultChecked={user.source === 'ADMIN'}
                onChange={handleChangeUserInfoRadio}
              />
              <Form.Field
                control={Radio}
                label="RECOMMEND"
                name="source"
                value="RECOMMEND"
                defaultChecked={user.source === 'RECOMMEND'}
                onChange={handleChangeUserInfoRadio}
              />
              <Form.Field
                control={Radio}
                label="ORGANIC"
                name="source"
                value="ORGANIC"
                defaultChecked={user.source === 'ORGANIC'}
                onChange={handleChangeUserInfoRadio}
              />
              <Form.Field
                control={Radio}
                label="MARKETING"
                name="source"
                value="MARKETING"
                defaultChecked={user.source === 'MARKETING'}
                onChange={handleChangeUserInfoRadio}
              />
            </Form.Group>
            <Form.Field>
              <label>아이템 수</label>
              <input
                name="itemCount"
                defaultValue={user.itemCount}
                onChange={handleChangeUserInfo}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="blue" content="변경" onClick={updateUserInfo} />
          <Button color="black" content="닫기" onClick={closeEditUserModal} />
        </Modal.Actions>
      </Modal>

      <Modal size="large" open={modalOpen} onClose={closeModal}>
        <Modal.Header>외부 서비스 연동 정보 업데이트</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group inline>
              <label>SNS 종류</label>
              <Form.Field
                label="인스타그램"
                control="input"
                type="radio"
                value="instagram"
                checked={isInstagram}
                onChange={_changeSnsType}
              />
              <Form.Field
                label="네이버"
                control="input"
                type="radio"
                value="naver"
                checked={isNaver}
                onChange={_changeSnsType}
              />
              <Form.Field
                label="네이버블로그"
                control="input"
                type="radio"
                value="naverBlog"
                checked={isNaverBlog}
                onChange={_changeSnsType}
              />
              <Form.Field
                label="올리브영"
                control="input"
                type="radio"
                value="oliveYoung"
                checked={isOliveYoung}
                onChange={_changeSnsType}
              />
            </Form.Group>
            {isInstagram && (
              <>
                <Form.Field>
                  <label>인스타계정</label>
                  <Input
                    size="mini"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Form.Field>
                <Form.Field>
                  <label>게시글수</label>
                  <Input
                    size="mini"
                    type="number"
                    value={postCount}
                    onChange={(e) => setPostCount(Number(e.target.value))}
                  />
                </Form.Field>
                <Form.Field>
                  <label>팔로워수</label>
                  <Input
                    size="mini"
                    type="number"
                    value={followerCount}
                    onChange={(e) => setFollowerCount(Number(e.target.value))}
                  />
                </Form.Field>
              </>
            )}

            {isNaver && (
              <>
                <Form.Field>
                  <label>네이버 id</label>
                  <Input
                    size="mini"
                    value={naverId}
                    onChange={(e) => setNaverId(e.target.value)}
                  />
                </Form.Field>
              </>
            )}

            {isNaverBlog && (
              <>
                <Form.Field>
                  <label>네이버 블로그 id</label>
                  <Input
                    size="mini"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Form.Field>
                <Form.Field>
                  <label>이웃수</label>
                  <Input
                    size="mini"
                    type="number"
                    value={buddyCount}
                    onChange={(e) => setBuddyCount(Number(e.target.value))}
                  />
                </Form.Field>
                <Form.Field>
                  <label>방문자수</label>
                  <Input
                    size="mini"
                    type="number"
                    value={visitorCount}
                    onChange={(e) => setVisitorCount(Number(e.target.value))}
                  />
                </Form.Field>
              </>
            )}

            {isOliveYoung && (
              <>
                <Form.Field>
                  <label>올리브영 닉네임</label>
                  <Input
                    size="mini"
                    value={oliveYoungInfo.username}
                    onChange={(e) =>
                      _changeOliveYoungValue('username', e.target.value)
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <label>
                    올리브영 user key (url에서 ?key=어쩌고저쩌고 값)
                  </label>
                  <Input
                    size="mini"
                    value={oliveYoungInfo.userKey}
                    onChange={(e) =>
                      _changeOliveYoungValue('userKey', e.target.value)
                    }
                  />
                </Form.Field>
                <Form.Group inline>
                  <label>올리브영 등급</label>
                  {OLIVEYOUNG_MEMBERSHIP.map((o) => (
                    <Form.Field
                      key={o}
                      label={o}
                      control="input"
                      type="radio"
                      checked={oliveYoungInfo.membership === o}
                      onChange={() => _changeOliveYoungValue('membership', o)}
                    />
                  ))}
                </Form.Group>
                <Form.Field>
                  <label>올리브영 랭킹 (없으면 빈값 유지)</label>
                  <Input
                    size="mini"
                    type="number"
                    value={oliveYoungInfo.reviewerRanking}
                    onChange={(e) =>
                      _changeOliveYoungValue('reviewerRanking', e.target.value)
                    }
                  />
                </Form.Field>
                <Form.Group inline>
                  <label>피부 타입</label>
                  {SKIN_CATEGORIES.map((o) => (
                    <Form.Field
                      key={o}
                      label={o}
                      control="input"
                      type="radio"
                      checked={oliveYoungInfo.skinCategory === o}
                      onChange={() =>
                        _changeOliveYoungSkinTypes('skinCategory', o)
                      }
                    />
                  ))}
                </Form.Group>
                <Form.Group inline>
                  <label>피부 톤</label>
                  {SKIN_TONES.map((o) => (
                    <Form.Field
                      key={o}
                      label={o}
                      control="input"
                      type="radio"
                      checked={oliveYoungInfo.skinTone === o}
                      onChange={() => _changeOliveYoungSkinTypes('skinTone', o)}
                    />
                  ))}
                </Form.Group>
                <Form.Group inline>
                  <label>피부 고민</label>
                  {SKIN_CONCERNS.map((o) => (
                    <Form.Field
                      key={o}
                      label={o}
                      control="input"
                      type="checkbox"
                      checked={oliveYoungInfo.skinConcerns.includes(o)}
                      onChange={() =>
                        _changeOliveYoungSkinTypes('skinConcerns', o)
                      }
                    />
                  ))}
                </Form.Group>
              </>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="blue"
            content="업데이트"
            onClick={_updateUserInfoSocials}
          />
          <Button color="black" content="닫기" onClick={closeModal} />
        </Modal.Actions>
      </Modal>

      <Modal size="small" open={addressModalOpen} onClose={closeAddressModal}>
        <Modal.Header>주소 목록</Modal.Header>
        <Modal.Content>
          <Table basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>이름</Table.HeaderCell>
                <Table.HeaderCell>전화번호</Table.HeaderCell>
                <Table.HeaderCell>우편번호</Table.HeaderCell>
                <Table.HeaderCell>기본주소</Table.HeaderCell>
                <Table.HeaderCell>상세주소</Table.HeaderCell>
                <Table.HeaderCell>요청사항</Table.HeaderCell>
                <Table.HeaderCell>출입번호</Table.HeaderCell>
                <Table.HeaderCell>기본주소여부</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {userAddressList.map((o) => {
                return (
                  <Table.Row key={o.id}>
                    <Table.Cell>{o.name}</Table.Cell>
                    <Table.Cell>{o.phone}</Table.Cell>
                    <Table.Cell>{o.zipCode}</Table.Cell>
                    <Table.Cell>{o.address1}</Table.Cell>
                    <Table.Cell>{o.address2}</Table.Cell>
                    <Table.Cell>{o.request}</Table.Cell>
                    <Table.Cell>{o.entranceCode}</Table.Cell>
                    <Table.Cell>{o.isDefault ? 'O' : 'X'}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" content="닫기" onClick={closeAddressModal} />
        </Modal.Actions>
      </Modal>

      <Modal size="small" open={socialModalOpen} onClose={closeSocialModal}>
        <Modal.Header>SNS 목록</Modal.Header>
        <Modal.Content>
          <Table basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>SNS 종류</Table.HeaderCell>
                <Table.HeaderCell>유저명</Table.HeaderCell>
                <Table.HeaderCell>업데이트 시점</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>인스타그램</Table.Cell>
                <Table.Cell>
                  {userSocials.instagram?.username ?? '-'}
                </Table.Cell>
                <Table.Cell>{userSocials.instagram?.t ?? '-'}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>네이버</Table.Cell>
                <Table.Cell>{userSocials.naver?.id ?? '-'}</Table.Cell>
                <Table.Cell>{userSocials.naver?.t ?? '-'}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>네이버블로그</Table.Cell>
                <Table.Cell>
                  {userSocials.naverBlog?.username ?? '-'}
                </Table.Cell>
                <Table.Cell>{userSocials.naverBlog?.t ?? '-'}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>올리브영</Table.Cell>
                <Table.Cell>
                  {userSocials.oliveYoung?.username ?? '-'}
                </Table.Cell>
                <Table.Cell>{userSocials.oliveYoung?.t ?? '-'}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" content="닫기" onClick={closeSocialModal} />
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
};

export default UserBasicInfo;
