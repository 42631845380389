import { ConfirmPopupComponent } from '@component/ConfirmPopup/ConfirmPopup';
import Achievement from '@container/Achievement';
import GoalCategory from '@container/Goal/GoalCategory';
import LabelCrmCreate from '@container/Label/LabelCrmCreate/LabelCrmCreate';
import { LabelCrmDetail } from '@container/Label/LabelCrmDetail/LabelCrmDetail';
import LabelCrmTemplate from '@container/Label/LabelCrmTemplate/LabelCrmTemplate';
import LabelTab from '@container/Label/LabelTab';
import PreAlarmForm from '@container/PreAlarmForm';
import TemporaryCash from '@container/SettingNew/TemporaryCash';
import NewUser from '@container/User/NewUser';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Redirect, Route } from 'react-router-dom';
import { Loader, Sidebar } from 'semantic-ui-react';
import LoadingIndicator from '../../component/LoadingIndicator/LoadingIndicator';
import getLocal, { setLocal } from '../../global/local';
import { InitStore } from '../../store';
import AchievementByChallenge from '../Achievement/AchievementByChallenge/AchievementByChallenge';
import AchievementDetail from '../Achievement/AchievementDetail';
import AchievementEvent from '../Achievement/AchievementEvent';
import GotchaForm from '../Benefit/Gotcha/GotchaForm';
import GotchaList from '../Benefit/Gotcha/GotchaList';
import GotchaWinners from '../Benefit/Gotcha/GotchaWinners';
import PedometerGiftiShopBuyers from '../Benefit/PedometerGiftiShop/PedometerGiftiShopBuyers/PedometerGiftiShopBuyers';
import PedometerGiftiShopItemCreate from '../Benefit/PedometerGiftiShop/PedometerGiftiShopItemCreate/PedometerGiftiShopItemCreate';
import PedometerGiftiShopItemList from '../Benefit/PedometerGiftiShop/PedometerGiftiShopItemList/PedometerGiftiShopItemList';
import BoardCreate from '../Board/BoardCreate';
import BoardEdit from '../Board/BoardEdit';
import BoardItemDetail from '../Board/BoardItemDetail';
import Faq from '../Board/Faq';
import FaqCreate from '../Board/Faq/FaqCreate';
import FaqEdit from '../Board/Faq/FaqEdit';
import Board from '../Board/index';
import Challenge from '../Challenge';
import ChallengeCreate from '../Challenge/ChallengeCreate';
import ChallengeCuration from '../Challenge/ChallengeCuration';
import ChallengeCurationCreateOrEdit from '../Challenge/ChallengeCuration/ChallengeCurationCreateOrEdit';
import ChallengeCurationDetail from '../Challenge/ChallengeCuration/ChallengeCurationDetail';
import ChallengeEdit from '../Challenge/ChallengeEdit';
import ChallengeItemDetail from '../Challenge/ChallengeItemDetail';
import ChallengeSticker from '../Challenge/ChallengeSticker';
import PointInfo from '../Challenge/PointInfo';
import Commerce from '../Commerce';
import CompanyDetail from '../Commerce/CompanyDetail';
import Blacklist from '../Collabo/Blacklist';
import CollaborationAdmin from '../Collabo/CollaboAdmin';
import Settlement from '../Collabo/Settlement/Settlement';
import GoalCreate from '../Goal/GoalCreate';
import GoalEdit from '../Goal/GoalEdit';
import GoalItemDetail from '../Goal/GoalItemDetail';
import Goal from '../Goal/index';
import Home from '../Home/index';
import Login from '../Login/index';
import Marketing from '../Marketing';
import Content from '../Marketing/Content';
import EventNew from '../Marketing/EventNew';
import EventCreate from '../Marketing/EventNew/EventCreate';
import EventEdit from '../Marketing/EventNew/EventEdit';
import Navigation from '../Navigation/index';
import Password from '../Password/Password';
import Question from '../Question';
import Race from '../Race/Race';
import RaceCreate from '../Race/RaceCreate';
import RaceDetail from '../Race/RaceDetail/RaceDetail';
import RaceEdit from '../Race/RaceEdit/RaceEdit';
import SettingNew from '../SettingNew';
import EventItem from '../SettingNew/EventItem';
import EventPoint from '../SettingNew/EventPoint/EventPoint';
import RestDay from '../SettingNew/RestDay/RestDay';
import UserAdmin from '../SettingNew/UserAdmin/UserAdmin';
import Statistics from '../Statistics';
import ChallengeSearch from '../Statistics/ChallengeSearch';
import Daily from '../Statistics/Daily';
import Total from '../Statistics/Total';
import Tag from '../Tag';
import UserDetail from '../User/UserDetail';
import './App.css';
import AppThunk from './App.thunk';
import { DataLabel } from './DataLabel';
import CollaboAchievement from '@container/Achievement/CollaboAchievement/CollaboAchievement';

setLocal('token', '');
const token = getLocal('newToken');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

type Props = {
  token: string | null;
  roles: string[];
  AppDidMount: ReturnType<typeof AppThunk>['AppDidMount'];
};

type States = {
  isMounted: boolean;
};

class App extends Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isMounted: false,
    };
  }

  async componentDidMount() {
    this.props
      .AppDidMount(token)
      .finally(() => this.setState({ isMounted: true }));
  }

  render() {
    if (!this.state.isMounted) {
      return <Loader active inline="centered" />;
    }
    const { roles } = this.props;
    return (
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <BrowserRouter>
          <div>
            <Navigation />
            <Sidebar.Pusher style={{ paddingTop: 77 }}>
              <Route exact path="/home" component={Home} />
              {roles.includes('DATA-LABEL') && (
                <>
                  <Route exact path="/data-label" component={DataLabel} />
                </>
              )}
              {roles.includes('GOAL') && (
                <>
                  <Route exact path="/goals" component={Goal} />
                  <Route
                    exact
                    path="/goals/:id(\d+)"
                    component={GoalItemDetail}
                  />
                  <Route
                    exact
                    path="/goals/:id(\d+)/edit"
                    component={GoalEdit}
                  />
                  <Route exact path="/goals/create" component={GoalCreate} />
                  <Route
                    exact
                    path="/goal-categories"
                    component={GoalCategory}
                  />
                </>
              )}
              {roles.includes('CHALLENGE') && (
                <>
                  <Route exact path="/challenges" component={Challenge} />
                  <Route
                    exact
                    path="/challenges/:id(\d+)"
                    component={ChallengeItemDetail}
                  />
                  <Route
                    exact
                    path="/challenges/:id(\d+)/edit"
                    component={ChallengeEdit}
                  />
                  <Route
                    exact
                    path="/challenges/:id(\d+)/pre-alarm"
                    component={PreAlarmForm}
                  />
                  <Route
                    exact
                    path="/challenges/create"
                    component={ChallengeCreate}
                  />
                  <Route
                    exact
                    path="/challenges/curations"
                    component={ChallengeCuration}
                  />
                  <Route
                    exact
                    path="/challenges/curations/:id(\d+)"
                    component={ChallengeCurationDetail}
                  />
                  <Route
                    exact
                    path="/challenges/curations/:id(\d+)/edit"
                    component={ChallengeCurationCreateOrEdit}
                  />
                  <Route
                    exact
                    path="/challenges/curations/create"
                    component={ChallengeCurationCreateOrEdit}
                  />
                  <Route
                    exact
                    path="/challenges/stickers"
                    component={ChallengeSticker}
                  />
                  <Route exact path="/point-infos" component={PointInfo} />
                </>
              )}
              {roles.includes('COMMERCE') && (
                <>
                  <Route exact path="/commerces" component={Commerce} />
                  <Route
                    exact
                    path="/companies/:id(\d+)"
                    component={CompanyDetail}
                  />
                  <Route
                    exact
                    path="/companies/:id(\d+)/edit"
                    component={CompanyDetail}
                  />
                </>
              )}
              {roles.includes('RACE') && (
                <>
                  <Route exact path="/race" component={Race} />
                  <Route exact path="/race/create" component={RaceCreate} />
                  <Route exact path="/race/:id(\d+)" component={RaceDetail} />
                  <Route
                    exact
                    path="/race/:id(\d+)/edit"
                    component={RaceEdit}
                  />
                </>
              )}
              {roles.includes('BOARD') && (
                <>
                  <Route exact path="/boards" component={Board} />
                  <Route
                    exact
                    path="/boards/:id(\d+)"
                    component={BoardItemDetail}
                  />
                  <Route
                    exact
                    path="/boards/:id(\d+)/edit"
                    component={BoardEdit}
                  />
                  <Route exact path="/create/boards" component={BoardCreate} />
                  <Route exact path="/faqs" component={Faq} />
                  <Route exact path="/faqs/:id(\d+)/edit" component={FaqEdit} />
                  <Route exact path="/faqs/create" component={FaqCreate} />
                </>
              )}
              {roles.includes('MARKETING') && (
                <>
                  <Route exact path="/marketing" component={Marketing} />
                  <Route exact path="/banner" component={Marketing} />
                  <Route exact path="/contents" component={Content} />
                  <Route exact path="/events" component={EventNew} />
                  <Route exact path="/events/create" component={EventCreate} />
                  <Route
                    exact
                    path="/events/:id(\d+)/edit"
                    component={EventEdit}
                  />
                </>
              )}
              {roles.includes('COLLABO') && (
                <>
                  <Route
                    exact
                    path="/collabo/blacklist"
                    component={Blacklist}
                  />
                  <Route
                    exact
                    path="/collabo/admin"
                    component={CollaborationAdmin}
                  />
                  <Route exact path="/settlement" component={Settlement} />
                </>
              )}
              {roles.includes('BENEFIT') && (
                <>
                  <Route
                    exact
                    path="/gotcha/winners"
                    component={GotchaWinners}
                  />
                  <Route exact path="/gotcha" component={GotchaList} />
                  <Route
                    exact
                    path="/gotcha/:id(\d+)/edit"
                    component={GotchaForm}
                  />
                  <Route exact path="/gotcha/create" component={GotchaForm} />
                  <Route
                    exact
                    path="/pedometer-gifti-shop/buyer"
                    component={PedometerGiftiShopBuyers}
                  />
                  <Route
                    exact
                    path="/pedometer-gifti-shop/item"
                    component={PedometerGiftiShopItemList}
                  />
                  <Route
                    exact
                    path="/pedometer-gifti-shop/item/create"
                    component={PedometerGiftiShopItemCreate}
                  />
                  <Route
                    exact
                    path="/pedometer-gifti-shop/item/:id(\d+)/edit"
                    component={PedometerGiftiShopItemCreate}
                  />
                </>
              )}
              {roles.includes('ACHIEVEMENT') && (
                <>
                  <Route
                    exact
                    path="/challenges/:id(\d+)/achievements"
                    component={AchievementByChallenge}
                  />
                  <Route
                    exact
                    path="/collabo-achievements"
                    component={CollaboAchievement}
                  />
                  <Route exact path="/achievements" component={Achievement} />
                  <Route
                    exact
                    path="/achievements/event"
                    component={AchievementEvent}
                  />
                  <Route
                    exact
                    path="/achievements/:id(\d+)"
                    component={AchievementDetail}
                  />
                </>
              )}
              {roles.includes('QUESTION') && (
                <Route exact path="/questions" component={Question} />
              )}
              {roles.includes('USER') && (
                <>
                  <Route exact path="/users" component={NewUser} />
                  <Route exact path="/users/:id(\d+)" component={UserDetail} />
                </>
              )}
              {roles.includes('TAG') && (
                <>
                  <Route exact path="/tags" component={Tag} />
                </>
              )}
              {roles.includes('LABEL') && (
                <>
                  <Route exact path="/labels" component={LabelTab} />
                  <Route
                    exact
                    path="/labels-crm-template"
                    component={LabelCrmTemplate}
                  />
                  <Route
                    exact
                    path="/labels-crm-template/:id(\d+)"
                    component={LabelCrmDetail}
                  />
                  <Route
                    exact
                    path="/labels-crm-template/create"
                    component={LabelCrmCreate}
                  />
                </>
              )}
              {roles.includes('SETTING') && (
                <>
                  <Route exact path="/sms-provider" component={SettingNew} />
                  <Route exact path="/rest-day" component={RestDay} />
                  <Route exact path="/user-admin" component={UserAdmin} />
                  <Route exact path="/event-point" component={EventPoint} />
                  <Route
                    exact
                    path="/temporary-cash"
                    component={TemporaryCash}
                  />
                  <Route exact path="/event-item" component={EventItem} />
                </>
              )}
              {roles.includes('STATISTICS') && (
                <>
                  <Route exact path="/statistics" component={Statistics} />
                  <Route exact path="/statistics/daily" component={Daily} />
                  <Route exact path="/statistics/total" component={Total} />
                  <Route
                    exact
                    path="/challenge-search"
                    component={ChallengeSearch}
                  />
                </>
              )}
              <Route exact path="/password" component={Password} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/" component={Home} />
              {!this.props.token && <Redirect to="/login" />}
              <LoadingIndicator />
              <ConfirmPopupComponent />
            </Sidebar.Pusher>
          </div>
        </BrowserRouter>
      </QueryClientProvider>
    );
  }
}

const mapStateToProps = (state: InitStore) => {
  const tokenState = state.app.get('token') ? state.app.get('token') : null;
  const rolesState = state.app.get('roles') ? state.app.get('roles') : [];
  return {
    token: tokenState as string,
    roles: rolesState as string[],
  };
};
const mapDispatchToProps = AppThunk;

export default connect(mapStateToProps, mapDispatchToProps)(App);
