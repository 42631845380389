type TagQueryKeys = {
  GET_BANNERS: undefined;
  COUPON_LIST: { keyword: string; ids?: number[] };
};

export const getQueryKey = <T extends keyof TagQueryKeys>(
  ...[key, params]: undefined extends TagQueryKeys[T]
    ? [T]
    : [T, TagQueryKeys[T] | 'KEY_ONLY']
) => {
  if (params === 'KEY_ONLY') return [key];
  return params ? [key, params] : [key];
};
