import React, { useCallback, useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Divider,
  Grid,
  Header,
  Menu,
} from 'semantic-ui-react';
import moment from 'moment';
import ReactDragList from 'react-drag-list';
import { bannerTypeObj, bannerTypes } from '../../../constant/bannerTypes';
import { apis } from '../../../api';
import BannerModal from './BannerModal';
import ChallengeCategoryHeader from './components/ChallengeCategoryHeader/ChallengeCategoryHeader';
import ChallengeCategoryRowItem from './components/ChallengeCategoryRowItem/ChallengeCategoryRowItem';
import {
  checkBannerTargetFullRegistered,
  confirmOnChangeDisplay,
} from './Banner.utils';
import BannerItem from './components/BannerItem/BannerItem';
import { Banner } from '@types';
import {
  ChallengeOption,
  EventOption,
  NoticeOption,
  RaceOption,
} from 'src/types/marketing.types';
import _ from 'lodash';

const subMenuList = [
  { text: '현재 노출중 & 노출 예정', value: 'PRESENT_DISPLAYED' },
  { text: '노출 전체', value: 'OLD_DISPLAYED' },
  { text: '전체', value: 'ALL' },
];

const BannerPage = ({
  challengeOptions,
  noticeOptions,
  eventOptions,
  raceOptions,
}: {
  challengeOptions: ChallengeOption[];
  noticeOptions: NoticeOption[];
  eventOptions: EventOption[];
  raceOptions: RaceOption[];
}) => {
  const [activeMenu, setActiveMenu] = useState(
    bannerTypeObj.challengeMain.value,
  );
  const [activeSubMenu, setActiveSubMenu] = useState(subMenuList[0].value);
  const [banners, setBanners] = useState<Banner[]>([]);
  const [filteredBanners, setFilteredBanners] = useState<Banner[]>([]);
  const [banner, setBanner] = useState<Partial<Banner> | undefined>();
  const [modalOpen, setModalOpen] = useState(false);
  const [additionalBannerTypes, setAdditionalBannerTypes] = useState([]);

  useEffect(() => {
    getBanners();
  }, [activeMenu]);

  const getBanners = async () => {
    const res = await apis.getBanners({
      bannerType: activeMenu,
      order: 'PRIORITY',
    });
    const { banners: _banners } = res;
    setBanners(_banners);
  };

  const filterBanners = (_banners: Banner[]) => {
    const _filteredBanners: Banner[] = [];
    _banners.forEach((b) => {
      if (
        activeSubMenu === subMenuList[0].value &&
        b.isDisplayed &&
        moment().isBefore(moment(b.endDatetime))
      ) {
        _filteredBanners.push(b);
      } else if (activeSubMenu === subMenuList[1].value && b.isDisplayed) {
        _filteredBanners.push(b);
      } else if (activeSubMenu === subMenuList[2].value) {
        _filteredBanners.push(b);
      }
    });

    setFilteredBanners(_filteredBanners);
  };

  const onClickMenu = useCallback((__: any, { value }: any) => {
    setActiveMenu(value);
  }, []);

  const onClickSubMenu = useCallback((__: any, { value }: any) => {
    setActiveSubMenu(value);
  }, []);

  useEffect(() => {
    if (!banners || banners.length === 0) return;
    filterBanners(banners);
  }, [activeSubMenu, banners]);

  const showModal = useCallback((_banner: Partial<Banner> | undefined) => {
    setBanner(_banner);
    setModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setBanner(undefined);
    setModalOpen(false);
  }, []);

  const onChangeDisplay = async (bannerId: number) => {
    const _banner = banners.find((b) => b.id === bannerId);
    if (!_banner) return;

    const updated = { ..._banner, isDisplayed: !_banner.isDisplayed };

    if (!confirmOnChangeDisplay(updated, challengeOptions)) return;

    // 챌린지, 랜선대회 배너일 경우 모집인원이 마감된 상황인지 확인 후 처리
    let isForced = false;
    if (await checkBannerTargetFullRegistered(updated)) {
      if (
        !window.confirm(
          `배너에 연결된 ${
            updated.landingType === 'CHALLENGE' ? '챌린지' : '랜선대회'
          }의 선착순 인원이 마감되었습니다. 그래도 노출하시겠습니까?`,
        )
      ) {
        return;
      }
      isForced = true;
    }

    await apis.updateBannerDisplayed(bannerId, {
      isDisplayed: updated.isDisplayed,
      isForced,
    });

    const updatedBanners = banners.map((b) =>
      b.id === bannerId ? updated : b,
    );
    setBanners(updatedBanners);
    filterBanners(updatedBanners);
  };

  const addBanner = async (originBanner: Banner) => {
    const _banner = _.cloneDeep(originBanner);
    _banner.startDatetime = moment(_banner.startDatetime)
      .subtract(9, 'h')
      .format('YYYY-MM-DD HH:mm:ss');
    _banner.endDatetime = moment(_banner.endDatetime)
      .subtract(9, 'h')
      .format('YYYY-MM-DD HH:mm:ss');
    _banner.landingDetail = String(_banner.landingDetail);
    _banner.isDisplayed = false;

    const res = await apis.addBanner(_banner);
    for (let i = 0; i < additionalBannerTypes.length; i++) {
      const additionalBanner = { ..._banner };
      additionalBanner.bannerType = additionalBannerTypes[i];
      await apis.addBanner(additionalBanner);
    }
    setAdditionalBannerTypes([]);
    alert(!_banner.id ? '배너 추가가 완료되었어요' : '복사가 완료되었어요 ');
    const { banner: newBanner } = res;
    const _banners = [newBanner, ...banners];
    setBanners(_banners);
    filterBanners(_banners);
    setActiveSubMenu(subMenuList[2].value);
    closeModal();
  };

  const editBanner = async (_banner: Banner) => {
    _banner.startDatetime = moment(_banner.startDatetime)
      .subtract(9, 'h')
      .format('YYYY-MM-DD HH:mm:ss');
    _banner.endDatetime = moment(_banner.endDatetime)
      .subtract(9, 'h')
      .format('YYYY-MM-DD HH:mm:ss');
    _banner.landingDetail = String(_banner.landingDetail);
    const res = await apis.updateBanner(_banner.id, _banner);
    for (let i = 0; i < additionalBannerTypes.length; i++) {
      const additionalBanner = { ..._banner };
      additionalBanner.bannerType = additionalBannerTypes[i];
      await apis.addBanner(additionalBanner);
    }
    setAdditionalBannerTypes([]);
    const { banner: newBanner } = res;

    const _banners = banners.map((b) =>
      b.id === newBanner.id ? newBanner : b,
    );
    setBanners(_banners);
    filterBanners(_banners);
    closeModal();
  };

  const deleteBanner = async (bannerId: number) => {
    await apis.deleteBanner(bannerId);
    alert('배너가 삭제되었어요');

    const _banners = banners.filter((b) => b.id !== bannerId);
    setBanners(_banners);
    filterBanners(_banners);
  };

  const handleUpdate = async (__: any, updated: any) => {
    const priorityById: {
      [key: number]: number;
    } = {};
    filteredBanners.forEach((b) => {
      priorityById[b.id] = b.priority;
    });

    const priorityData: {
      id: number;
      priority: number;
    }[] = [];
    updated.forEach((item: any, idx: number) => {
      item.priority = priorityById[filteredBanners[idx].id];
      priorityData.push({ id: item.id, priority: idx + 1 });
    });
    setFilteredBanners([...updated]);

    await apis.updateBannerPriority({ priorityData });
  };

  // 현재 우선노출 되고 있는지 여부
  const hasHighestPriorityNow = (_banner: Banner) => {
    if (
      !_banner.info.reservationStartDatetime ||
      !_banner.info.reservationEndDatetime
    )
      return false;
    return moment().isBetween(
      moment(_banner.info.reservationStartDatetime),
      moment(_banner.info.reservationEndDatetime),
      'seconds',
      '[]',
    );
  };

  const getBackgroundColor = (_banner: Banner) => {
    if (moment().isBefore(_banner.startDatetime)) return 'lightGray';
    else if (hasHighestPriorityNow(_banner)) return 'lightBlue';
    return 'transparent';
  };

  return (
    <div>
      <Header as="h3">
        배너 관리
        <Button
          style={{ marginLeft: 10 }}
          size="tiny"
          value={{}}
          onClick={() => showModal({} as Partial<Banner>)}
        >
          추가
        </Button>
      </Header>

      <Menu pointing secondary>
        {bannerTypes.map((bannerType) => (
          <Menu.Item
            key={bannerType.value}
            name={bannerType.text}
            value={bannerType.value}
            active={activeMenu === bannerType.value}
            onClick={onClickMenu}
          />
        ))}
      </Menu>

      <Breadcrumb style={{}}>
        {subMenuList.map((subMenu, idx) => (
          <React.Fragment key={idx}>
            {idx > 0 && <Breadcrumb.Divider> / </Breadcrumb.Divider>}
            <Breadcrumb.Section
              style={{ margin: '0 10px', color: '#000000' }}
              value={subMenu.value}
              active={activeSubMenu === subMenu.value}
              onClick={onClickSubMenu}
              content={subMenu.text}
            />
          </React.Fragment>
        ))}
      </Breadcrumb>

      <Divider hidden />

      {activeMenu === bannerTypeObj.challengeCategory.value ? (
        <ChallengeCategoryHeader />
      ) : (
        <Grid style={{ fontWeight: 700 }}>
          <Grid.Row>
            <Grid.Column width={1}>id</Grid.Column>
            <Grid.Column width={2}>랜딩타입</Grid.Column>
            <Grid.Column width={2}>제목</Grid.Column>
            <Grid.Column width={2}>이미지</Grid.Column>
            <Grid.Column width={1}>글씨색</Grid.Column>
            <Grid.Column width={1}>시작일</Grid.Column>
            <Grid.Column width={1}>종료일</Grid.Column>
            <Grid.Column width={2} textAlign={'center'}>
              우선노출 기간
            </Grid.Column>
            <Grid.Column width={1}>공개방식</Grid.Column>
            <Grid.Column width={1}>노출</Grid.Column>
            <Grid.Column width={1}>수정/복사/삭제</Grid.Column>
          </Grid.Row>
        </Grid>
      )}
      {filteredBanners.length > 0 ? (
        <ReactDragList
          key={filteredBanners.map((o) => o.id).join('-')}
          rowKey="id"
          dataSource={[...filteredBanners]}
          handles={false}
          onUpdate={handleUpdate}
          disabled={activeSubMenu !== 'PRESENT_DISPLAYED'}
          row={(b: any) =>
            activeMenu === bannerTypeObj.challengeCategory.value ? (
              <ChallengeCategoryRowItem
                b={b}
                getBackgroundColor={getBackgroundColor}
                onChangeDisplay={onChangeDisplay}
                showModal={showModal}
                addBanner={addBanner}
                deleteBanner={deleteBanner}
              />
            ) : (
              <BannerItem
                key={b.id}
                banner={b}
                handleChangeDisplay={onChangeDisplay}
                addBanner={addBanner}
                deleteBanner={deleteBanner}
                showModal={showModal}
              />
            )
          }
        />
      ) : (
        <Grid>
          <Grid.Column width={16}>배너가 없습니다</Grid.Column>
        </Grid>
      )}

      {modalOpen && (
        <BannerModal
          modalOpen={modalOpen}
          closeModal={closeModal}
          addOrEditBanner={!banner?.id ? addBanner : editBanner}
          initBanner={banner}
          challengeOptions={challengeOptions}
          noticeOptions={noticeOptions}
          contentOptions={[]}
          eventOptions={eventOptions}
          raceOptions={raceOptions}
          additionalBannerTypes={additionalBannerTypes}
          setAdditionalBannerTypes={setAdditionalBannerTypes}
        />
      )}
    </div>
  );
};

export default BannerPage;
